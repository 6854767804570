import React from 'react';
import { Box, Typography, Stack, Paper } from '@mui/material';
import { Euro, AttachMoney, CurrencyRuble } from '@mui/icons-material';
import bitcoinImage from '../../../media/svg/bitcoin.svg';

export function DeclaredBalance(props) {
  const { getUserTotalIn, declaredValue } = props;
  const balanceInformation = [
    { value: getUserTotalIn('euro', true), icon: <Euro color='primary' /> },
    {
      value: getUserTotalIn('dollar', true),
      icon: <AttachMoney color='primary' />,
    },
    {
      value: getUserTotalIn('ruble', true),
      icon: <CurrencyRuble color='primary' />,
    },
  ];
  return (
    <Box minHeight={246} component={Paper} p={3}>
      <Typography fontSize={24} fontWeight={700} mb={5}>
        Declared cryptocurrency (BTC) balance
      </Typography>
      <Stack gap={2} flexDirection='row'>
        <Box alignItems='center' display='flex' gap={1}>
          <img src={bitcoinImage} alt='bitoin icon' />
          <Typography fontSize={24}>{declaredValue}</Typography>
        </Box>
        <Stack alignItems='center' direction='row' gap={2} flexWrap='wrap'>
          {balanceInformation.map((item, index) => {
            return (
              <Box display='flex' alignItems='center' key={index + item}>
                {item.icon}
                <Typography fontSize={20}>{item.value.toFixed(2)}</Typography>
              </Box>
            );
          })}
        </Stack>
      </Stack>
    </Box>
  );
}
