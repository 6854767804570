import React, { useState } from 'react';
import CloseIcon from '@mui/icons-material/Close';
import IconButton from '@mui/material/IconButton';
import {
  TextField,
  InputLabel,
  MenuItem,
  FormControl,
  Select,
  Radio,
  RadioGroup,
  FormControlLabel,
  FormLabel,
  Button,
  Modal,
  Box,
  Typography,
} from '@mui/material';
import { useForm, Controller } from 'react-hook-form';
import { baseUrl, getDate } from '../../utils';
export function OperationModal({ settings, handleCLick, currency, id }) {
  const [method, setMethod] = useState('Swift or SEPA');
  const [toCurrency, setToCurrency] = useState('');
  const [fromAmount, setFromAmount] = useState(0);
  const [toAmount, setToAmount] = useState(0);
  const {
    register,
    formState: { isValid },
    handleSubmit,
    control,
  } = useForm({
    defaultValues: {
      currency: '',
      cryptoAddress: '',
      amount: toAmount,
      amountCrypto: fromAmount,
    },
  });

  async function onSubmit(data) {
    if (!fromAmount || !toAmount) return alert('Not valid data');
    delete data.currency;
    const _data = {
      info: {
        ...data,
        amount: +fromAmount,
        amountCrypto: toAmount,
        date: getDate(),
        status: 'Need confirm',
        type: settings.type === 'sell' ? 'Withdrawal' : 'Deposit',
      },
      id,
    };
    try {
      const response = await fetch(baseUrl('update/history'), {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(_data),
      });
      const responseJSON = await response;
      if (!responseJSON.ok) {
        new Error();
      }
    } catch (error) {
      new Error(error);
      handleCLick({ isOpen: false });
    }
    handleCLick({ isOpen: false });
  }

  const handleFromAmountChange = (event) => {
    setFromAmount(event.target.value);
    setToAmount(event.target.value * currency.bitcoin[toCurrency]);
  };

  const handleToAmountChange = (event) => {
    setToAmount(event.target.value);
    setFromAmount(event.target.value * currency[toCurrency].bitcoin);
  };

  const radioItems = [
    { item: 'dollar', label: 'USD' },
    { item: 'euro', label: 'EUR' },
    { item: 'ruble', label: 'RUB' },
  ];
  return (
    <Modal
      open={settings.isOpen}
      onClose={() => handleCLick({ isOpen: false })}>
      <Box sx={style} component={'form'} onSubmit={handleSubmit(onSubmit)}>
        <Box display='flex' justifyContent='space-between' alignItems='center'>
          <Typography fontSize={32} fontWeight={700}>
            {settings.heading}
          </Typography>
          <IconButton
            onClick={() => handleCLick({ isOpen: false })}
            aria-label='close modal'>
            <CloseIcon />
          </IconButton>
        </Box>
        <FormControl component='fieldset'>
          <FormLabel component='legend'>
            Currency <span style={{ color: 'red' }}>*</span>
          </FormLabel>

          <Controller
            rules={{ required: true }}
            control={control}
            name='currency'
            render={({ field }) => (
              <RadioGroup {...field}>
                {radioItems.map((radio) => {
                  return (
                    <FormControlLabel
                      key={radio.item}
                      value={radio.item}
                      control={
                        <Radio
                          onChange={(e) => setToCurrency(e.target.value)}
                        />
                      }
                      label={radio.label}
                    />
                  );
                })}
              </RadioGroup>
            )}
          />
        </FormControl>
        <TextField
          {...register('amount', { required: true })}
          label='Amount'
          value={toAmount}
          onChange={handleToAmountChange}
          type={'number'}
          variant='outlined'
          disabled={!toCurrency ? true : false}
        />
        <TextField
          {...register('amountCrypto', { required: true })}
          label='Amount cryptocurrency'
          value={fromAmount}
          onChange={handleFromAmountChange}
          variant='outlined'
          type={'number'}
          disabled={!toCurrency ? true : false}
        />

        <TextField
          disabled={settings.type === 'buy' ? true : false}
          {...register('cryptoAddress', {
            required: settings.type === 'buy' ? false : true,
          })}
          id='cryptoAddress'
          label='Wallet address'
          variant='outlined'
        />
        <FormControl fullWidth>
          <InputLabel id='sellectMethod'>Method *</InputLabel>
          <Select
            {...register('method', { required: true })}
            labelId=''
            id='sellectMethod'
            value={method}
            label='Method *'
            onChange={(e) => setMethod(e.target.value)}>
            <MenuItem value={'Swift or SEPA'}>Swift or SEPA</MenuItem>
            <MenuItem value={'Visa or Mastercard'}>Visa or Mastercard</MenuItem>
            <MenuItem value={'Bank transfer PaySafer'}>
              Bank transfer PaySafer
            </MenuItem>
            <MenuItem value={'Qiwi PaySafer'}>Qiwi PaySafer</MenuItem>
            <MenuItem value={'Bitcoin PaySafer'}>Bitcoin PaySafer</MenuItem>
            <MenuItem value={'Visa/Mastercard PaySafer'}>
              Visa/Mastercard PaySafer
            </MenuItem>
          </Select>
        </FormControl>
        <Button
          color={settings.type === 'buy' ? 'primary' : 'error'}
          size={'large'}
          variant='contained'
          type={'submit'}
          disabled={!isValid}>
          {settings.type === 'buy' ? 'DEPOSIT' : 'WITHDRAWAL'}
        </Button>
      </Box>
    </Modal>
  );
}

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: '100%',
  maxWidth: 420,
  overflow: 'scroll',
  bgcolor: '#ffffff',
  p: 3,
  display: 'flex',
  flexDirection: 'column',
  rowGap: 4,
  height: '100%',
};
