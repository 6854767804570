import React from 'react';
import { Box, Container, Paper, IconButton } from '@mui/material';
import { Menu } from '@mui/icons-material';
import { useSelector, useDispatch } from 'react-redux';
import useMediaQuery from '@mui/material/useMediaQuery';
import { changeState } from '../../redux/slices/burgerState';
export default function Header(props) {
  const isActiveBurger = useSelector((state) => state.burgerReducer.burgerMenu);
  const dispatch = useDispatch();

  const matches = useMediaQuery('(max-width:1024px)');

  return (
    <Box
      component='header'
      bgcolor='#fff'
      minHeight={40}
      position='fixed'
      width='100%'
      zIndex={101}
      left={0}
      ml='250px'
      sx={{
        '@media (max-width: 1024px)': {
          ml: '0',
          mr: '0',
        },
      }}
      top={0}>
      <Container>
        {matches ? (
          <IconButton onClick={() => dispatch(changeState())}>
            <Menu color='primary' />
          </IconButton>
        ) : (
          <></>
        )}
      </Container>
    </Box>
  );
}
