import { Route, Routes, HashRouter } from "react-router-dom";
import React, { Suspense } from "react";
import { Box, CircularProgress } from "@mui/material";

import { HomePage } from "./pages/HomePage/HomePage.jsx";
import RegistrationPage from "./pages/registration/registration.jsx";
import LoginPage from "./pages/login/login.jsx";
import PersonalAreaPage from "./pages/personal-area/personal-area.jsx";
import Dashboard from "./pages/dashbord/dashbord.jsx";
import Accumulation from "./pages/accumulation/accumulation.jsx";
import Documents from "./pages/documents/documents.jsx";
import BitcoinPage from "./pages/Bitcoin.jsx";
import DeclaredPage from "./pages/Declared.jsx";
import PageForgetPass from "./pages/ForgetPassword.jsx";

const loading = (
  <Box
    display='flex'
    justifyContent='center'
    alignItems='center'
    width='100%'
    height='100%'
  >
    <CircularProgress />
  </Box>
);
function App() {
  return (
    // <Router>
    // <Routes>
    //   <Route path="/" element={<HomePage />}></Route>
    //   <Route path="/login" element={<LoginPage />}></Route>
    //    <Route path="/new-password" element={<PageForgetPass />}></Route>
    //   <Route path="/registration" element={<RegistrationPage />}></Route>
    //   <Route path="/wallet/*" element={<PersonalAreaPage />}>
    //     <Route path="dashboard" element={<Dashboard />}></Route>
    //     <Route path="declared" element={<DeclaredPage />}></Route>
    //     <Route path="accumulation" element={<Accumulation />}></Route>
    //     <Route path="bitcoin" element={<BitcoinPage />}></Route>
    //     <Route path="documents" element={<Documents />}></Route>
    //   </Route>
    // </Routes>
    // </Router>
    <HashRouter>
      <Suspense fallback={loading}>
        <Routes>
          <Route
            exact
            path='/'
            name='Home Page'
            element={<HomePage />}
          />
          <Route
            path='/registration'
            name='Registration Page'
            element={<RegistrationPage />}
          />
          <Route
            path='/login'
            name='Login Page'
            element={<LoginPage />}
          />
          <Route
            path='/wallet/*'
            name='Wallet Page'
            element={<PersonalAreaPage />}
          >
            <Route
              path='dashboard'
              name='Login Page'
              element={<Dashboard />}
            />
            <Route
              path='accumulation'
              name='Login Page'
              element={<Accumulation />}
            />
            <Route
              path='bitcoin'
              name='Login Page'
              element={<BitcoinPage />}
            />
            <Route
              path='documents'
              name='Login Page'
              element={<Documents />}
            />
            <Route
              path='declared'
              name='Login Page'
              element={<DeclaredPage />}
            />
          </Route>
          <Route
            path='/new-password'
            name='New password page'
            element={<PageForgetPass />}
          />
          <Route
            path='/login'
            name='Login Page'
            element={<LoginPage />}
          />
        </Routes>
      </Suspense>
    </HashRouter>
  );
}

export default App;
