import React from "react";
import { Box, Typography, Paper, Stack, Button, Link } from "@mui/material";
import { useNavigate } from "react-router-dom";

export function UserInformationCard(props) {
  const { email, firstName, lastName, number } = props;
  const navigate = useNavigate();

  function navigateTo() {
    window.localStorage.removeItem("id");
    navigate("/new-password");
  }
  return (
    <Box
      minWidth={320}
      component={Paper}
      p={3}
    >
      <Stack
        flexDirection='column'
        gap={3}
      >
        <StackItem
          property={"First-name"}
          value={<Typography fontSize={20}>{firstName}</Typography>}
        />
        <StackItem
          property={"Last-name"}
          value={<Typography fontSize={20}>{lastName}</Typography>}
        />
        <StackItem
          property={"Email"}
          value={
            <Link href={`mailto:${email}`}>
              <Typography
                color='blue'
                fontSize={20}
              >
                {email}
              </Typography>
            </Link>
          }
        />
        <StackItem
          property={"Phone number"}
          value={<Typography fontSize={20}>{number}</Typography>}
        />
        <StackItem
          property={"Password"}
          value={
            <Button onClick={() => navigateTo()}>
              <Typography fontSize={20}>Change password</Typography>
            </Button>
          }
        />
      </Stack>
    </Box>
  );
}

function StackItem(props) {
  const { property, value } = props;
  return (
    <Box
      display='flex'
      justifyContent='space-between'
      alignItems='center'
      columnGap={8}
      rowGap={1}
    >
      <Typography
        fontWeight={500}
        fontSize={24}
      >
        {property}
      </Typography>
      {value}
    </Box>
  );
}
