import React from "react";
import * as ReactDOMClient from "react-dom/client";
import App from "./components/App.jsx";
import { createTheme } from "@mui/material/styles";
import { Provider } from "react-redux";
import store from "./redux/store.js";
import reportWebVitals from "./reportWebVitals";
import { CustomThemeProvider } from "./ThemeProvider.js";

import "./fonts.css";
import { CssBaseline } from "@mui/material";

// import "./style.scss";

const theme = createTheme({
  typography: {
    fontFamily: [
      "Gilroy-Bold",
      "Gilroy-Medium",
      "Gilroy-Regular",
      "helveticaneuecyr-2",
      "monument-extended",
    ].join(","),
  },
});
// import "./components/ui/input/_input.scss";

const container = document.querySelector("#root");
const root = ReactDOMClient.createRoot(container);
root.render(
  <CustomThemeProvider>
    <CssBaseline>
      <Provider store={store}>
        <App />
      </Provider>
    </CssBaseline>
  </CustomThemeProvider>
);

reportWebVitals();
