import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';

const initialState = {
  burgerMenu: false,
};

const burgerSlice = createSlice({
  name: 'burgerMenu',
  initialState,
  reducers: {
    changeState(state, action) {
      state.burgerMenu = !state.burgerMenu;
    },
  },
});

export const { changeState } = burgerSlice.actions;
export const burgerReducer = burgerSlice.reducer;
