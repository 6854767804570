import { Box, Container, Button, Link, Typography } from '@mui/material';
import { useNavigate } from 'react-router-dom';

import logo from '../../../media/logo.png';

export function HomePageHeader() {
  const navigate = useNavigate();
  function navigateTo(url) {
    navigate(url);
  }
  return (
    <Box borderBottom='1px solid #fff' sx={styles.header} component={'header'}>
      <Container sx={styles.container}>
        <Link href='/'>
          <img width={70} height={70} src={logo} alt='logotype' />
        </Link>
        <Link
          target='_blank'
          href='mailto:support@legalconnect.finance'
          sx={{ textDecoration: 'none' }}>
          <Typography
            sx={{
              '@media (max-width: 720px)': {
                fontSize: 16,
              },
            }}
            fontSize={32}
            color='#fff'>
            support@legalconnect.finance
          </Typography>
        </Link>
        <Button sx={styles.button} onClick={() => navigateTo('registration')}>
          Sign Up
        </Button>
      </Container>
    </Box>
  );
}
const styles = {
  container: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    gap: 2,
    flexWrap: 'wrap',
    '@media (max-width: 720px)': {
      justifyContent: 'center',
    },
  },
  header: {
    minHeight: '60px',
    display: 'flex',
    alignItems: 'center',
    pt: 3,
    pb: 3,
  },
  button: {
    color: '#fff',
    fontSize: 18,
    fontWeight: 700,
  },
};
