import React from 'react';
import { Box, Typography } from '@mui/material';
import TableCustom from '../../table/table.jsx';
import { useOutletContext } from 'react-router-dom';

const Dashboard = () => {
  const { user, currency } = useOutletContext();

  const userTotalBalance = {
    bitcoin: user.balance.bitcoin * currency.bitcoin.dollar,
    litecoin: user.balance.litecoin * currency.litecoin.dollar,
    tether: user.balance.tether * currency.tether.dollar,
    ethereum: user.balance.ethereum * currency.ethereum.dollar,
    getTotal: function () {
      return this.bitcoin + this.litecoin + this.tether + this.ethereum;
    },
  };

  return (
    <Box sx={styles.page}>
      <Box sx={styles.wrapper}>
        <Box sx={styles.balance}>
          <Typography fontWeight={500} fontSize={50}>
            $ {userTotalBalance.getTotal().toFixed(2)}
          </Typography>
        </Box>
        <TableCustom userBalance={user.balance} currency={currency} />
      </Box>
    </Box>
  );
};

export default Dashboard;

const styles = {
  page: {
    backgroundColor: '#f4f9fd',
    height: '100%',
    width: '100%',
    padding: '0 40px',
    '@media (max-width: 425px)': {
      padding: '0 15px',
    },
  },
  wrapper: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    rowGap: 25,
  },
  balance: {
    display: 'inline-flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    width: '289px',
    height: '293px',
    backgroundColor: '#fff',
    borderRadius: '50%',
  },
};
