import React, { useState, useEffect } from "react";
import CoinPage from "./coin/coin.jsx";
import { useOutletContext } from "react-router-dom";

export default function DeclaredPage() {
  const { user, currency, getUserTotalIn } = useOutletContext();

  const pageData = {
    currencyName: "Declared",
    currencyAbbr: "cryptocurrency (BTC)",
    currencyImage: "/assets/icons/bitcoin.svg",
    currencyValue: user.balance.declaredBitcoin,
    toDollar: currency.bitcoin.dollar,
    toEuro: currency.bitcoin.euro,
    toRuble: currency.bitcoin.ruble,
    declared: true,
  };

  return (
    <CoinPage
      dataPage={pageData}
      user={user}
      currency={currency}
      getUserTotalIn={getUserTotalIn}
    ></CoinPage>
  );
}
