import React from "react";
import { ThemeProvider, createTheme } from "@mui/material/styles";

const theme = createTheme({
  shape: {
    borderRadius: 10,
  },
  typography: {
    // fontFamily: "Gilroy",
  },
  palette: {
    primary: {
      main: "rgba(0, 45, 255, 0.8)",
      dark: "#C4A887",
    },
    text: {
      primary: "#132232",
      secondary: "#72899e",
      disabled: "#919692",
    },
    success: {
      main: "#43C379",
    },
    error: {
      main: "#FF4C45",
    },
    warning: {
      main: "#FF9348",
    },
    secondary: {
      main: "#4285F4",
    },
    action: {
      disabledBackground: "#CACACA",
    },
    common: {
      white: "#fff",
    },
    // root: {
    //   border: "#E6E6E6",
    //   holder: "#F1F1F3",
    // },
  },
  breakpoints: {
    values: {
      xs: 0,
      sm: 640,
      md: 1024,
      lg: 1200,
      xl: 1600,
    },
  },
  components: {
    MuiTypography: {
      styleOverrides: {
        h1: {
          fontSize: "1.5625rem",
          fontWeight: 700,
          lineHeight: 1.2,
        },
        h2: {
          fontSize: "1.25rem",
          fontWeight: 700,
          lineHeight: 1.2,
        },
        subtitle1: {
          fontWeight: 500,
          lineHeight: 1.1875,
        },
        body1: {
          fontSize: "1rem",
          lineHeight: 1.1875,
        },
        caption: {
          lineHeight: 1.166,
        },
      },
    },
    MuiLink: {
      styleOverrides: {
        root: {
          textDecoration: "none",
          color: "#919692",
          lineHeight: 1,
        },
      },
      // defaultProps: {
      //   component: LinkBehaviour,
      // } as MuiLinkProps,
    },
    MuiMenuItem: {
      defaultProps: {
        disableRipple: true,
      },
    },
    MuiCard: {
      styleOverrides: {
        root: {
          // border: `1px solid ${borderColor}`,
          // boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          textTransform: "none",
          borderRadius: "8px",
          fontSize: "1.25rem",
          fontWeight: 700,
          lineHeight: 1.2,
        },
        outlined: {
          color: "#000",
        },
        containedPrimary: {
          color: "#fff",
          ":hover": {
            backgroundColor: "#fff",
            color: "rgba(0, 45, 255, 0.8)",
          },
        },

        sizeLarge: {
          minWidth: "270px",
          minHeight: "50px",
        },
        sizeMedium: {
          minWidth: "127px",
          minHeight: "40px",
        },
        sizeSmall: {
          minWidth: "50px",
        },
      },
    },
    MuiContainer: {
      styleOverrides: {
        root: ({ theme }) => ({
          "@media (min-width: 1200px)": {
            maxWidth: "1320px",
            paddingLeft: theme.spacing(1),
            paddingRight: theme.spacing(1),
          },
        }),
      },
    },
    MuiTextField: {
      styleOverrides: {
        root: {
          // TODO: ab/ add styles from design
          borderColor: "#bebebe",
        },
      },
    },
  },
});

export function CustomThemeProvider(props) {
  const { children } = props;
  return <ThemeProvider theme={theme}>{children}</ThemeProvider>;
}
