import React from "react";
import { FormLogin } from "../../form-login/form-login.jsx";
import { Box, Container } from "@mui/material";

const LoginPage = () => {
  return (
    <Box
      height='100%'
      display='flex'
      justifyContent='center'
      alignItems='center'
      bgcolor='#F2F2F2'
    >
      <Container
        sx={{ display: "flex", justifyContent: "center", alignItems: "center" }}
      >
        <FormLogin />
      </Container>
    </Box>
  );
};

export default LoginPage;
