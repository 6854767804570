import React, { useState } from 'react';
import MenuItem from '@mui/material/MenuItem';
import {
  Box,
  Select,
  TextField,
  Typography,
  Paper,
  Stack,
  Grid,
} from '@mui/material';

export default function Calculation({ currency }) {
  const [fromCurrency, setFromCurrency] = useState('bitcoin');
  const [toCurrency, setToCurrency] = useState('dollar');
  const [fromAmount, setFromAmount] = useState(0);
  const [toAmount, setToAmount] = useState(0);

  const handleFromAmountChange = (event) => {
    setFromAmount(event.target.value);
    setToAmount(event.target.value * currency[fromCurrency][toCurrency]);
  };

  const handleToAmountChange = (event) => {
    setToAmount(event.target.value);
    setFromAmount(event.target.value * currency[toCurrency][fromCurrency]);
  };
  const handleFromCurrencyChange = (event) => {
    setFromCurrency(event.target.value);
    setToAmount(fromAmount * currency[event.target.value][toCurrency]);
  };

  const handleToCurrencyChange = (event) => {
    setToCurrency(event.target.value);
    setToAmount(fromAmount * currency[fromCurrency][event.target.value]);
  };

  return (
    <Box width='100%' component={Paper} p={4}>
      <Stack>
        <Typography mb={2} fontSize={32}>
          1 Bitcoin equals
        </Typography>
        {/* <Box>
          <Typography>
            <Typography mb={1} fontSize={18} color='grey'>
              Dollar USA
            </Typography>
          </Typography>
        </Box> */}
        <Grid spacing={2} container>
          <Grid xs={6} item>
            <TextField
              label='Amount'
              value={fromAmount}
              onChange={handleFromAmountChange}
              fullWidth
              type='number'
            />
          </Grid>
          <Grid xs={6} item>
            <Select
              value={fromCurrency}
              onChange={handleFromCurrencyChange}
              fullWidth>
              <MenuItem value={'bitcoin'}>Bitcoin</MenuItem>
              <MenuItem value={'litecoin'}>Litecoin</MenuItem>
              <MenuItem value={'ethereum'}>Ethereum</MenuItem>
              <MenuItem value={'tether'}>Tether</MenuItem>
            </Select>
          </Grid>
          <Grid xs={6} item>
            <TextField
              value={toAmount}
              onChange={handleToAmountChange}
              fullWidth
              type='number'></TextField>
          </Grid>
          <Grid xs={6} item>
            <Select
              value={toCurrency}
              onChange={handleToCurrencyChange}
              fullWidth>
              <MenuItem value={'dollar'}>Dollar USA</MenuItem>
              <MenuItem value={'euro'}>Euro</MenuItem>
              <MenuItem value={'ruble'}>Russian ruble</MenuItem>
            </Select>
          </Grid>
        </Grid>
      </Stack>
    </Box>
  );
}
