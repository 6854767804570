import React from 'react';
import {
  Table,
  TableContainer,
  TableCell,
  TableHead,
  TableBody,
  TableRow,
  Paper,
  Typography,
  Button,
} from '@mui/material';
import axios from '../../axios.js';
import { getColorStatus } from '../../utils.js';

// import "./order-table.scss";
export default function OrderTable(props) {
  const { history, declared } = props;
  // const data = userInfo.history?.bitcoin;
  const id = window.localStorage.getItem('id');

  async function handleClick(data) {
    const { index, key, value } = data;
    try {
      await axios.put('/update/history', { id, index, key, value });
    } catch (error) {
      console.error(error);
    }
    alert(
      'Запрос в ожидании. Пожалуйста, подождите пока администратор подтвердит вашу оплату'
    );
  }
  const tableHeadings = [
    'Date',
    'Status',
    'Type',
    'Wallet address',
    'Method',
    'Hash',
    'Amount',
    'Confirm',
  ];
  return (
    <TableContainer sx={{ maxHeight: 600, overflow: 'auto' }} component={Paper}>
      <Table>
        <TableHead>
          <TableRow>
            {tableHeadings.map((heading) => {
              return (
                <TableCell key={heading}>
                  <Typography
                    textAlign='center'
                    color='text.secondary'
                    fontWeight={500}
                    fontSize={20}>
                    {heading}
                  </Typography>
                </TableCell>
              );
            })}
          </TableRow>
        </TableHead>
        <TableBody>
          {history
            .map((order, index) => {
              const {
                date,
                method,
                hash,
                status,
                amount,
                type,
                cryptoAddress,
              } = order;

              const disableButton =
                status === 'Pending' || status === 'Confirm ' || declared
                  ? true
                  : false;
              return (
                <TableRow key={index}>
                  <TableCell align='center'>{date}</TableCell>
                  <TableCell
                    align='center'
                    sx={{ color: getColorStatus(status) }}>
                    {status}
                  </TableCell>
                  <TableCell
                    align='center'
                    sx={{ color: getColorStatus(type) }}>
                    {type}
                  </TableCell>
                  <TableCell sx={styles.fixWidth} align='center'>
                    {cryptoAddress || 'Not specified'}
                  </TableCell>
                  <TableCell align='center'>{method}</TableCell>
                  <TableCell sx={styles.fixWidth} align='center'>
                    {hash}
                  </TableCell>
                  <TableCell align='center'>{amount}</TableCell>
                  <TableCell>
                    <Button
                      variant='contained'
                      fullWidth
                      disabled={disableButton}
                      onClick={() =>
                        handleClick({
                          index,
                          key: 'status',
                          value: 'Pending',
                          id,
                        })
                      }>
                      Confirm
                    </Button>
                  </TableCell>
                </TableRow>
              );
            })
            .reverse()}
        </TableBody>
      </Table>
    </TableContainer>
  );
}

const styles = {
  fixWidth: {
    maxWidth: 150,
    overflowX: 'auto',
  },
};

// export default function OrderTable(props) {
//   const { addClass, userInfo, declared } = props;
//   const data = userInfo.history?.bitcoin;
//   const id = window.localStorage.getItem("id")

//   async function handleClick(data) {
//     const { index, key, value} = data;
//     try {
//       await axios.put("/update/history", { id, index, key, value});

//     } catch (error) {
//       console.error(error);
//     }
//     alert("Запрос в ожидании. Пожалуйста, подождите пока администратор подтвердит вашу оплату")
//   }
//   return (
//    <div className="table-wrapper">
//     <table className={`${addClass} order-table`}>
//       <thead className="order-table__head">
//         <tr className="order-table__row">
//           <th className="order-table__heading">Date</th>
//           <th className="order-table__heading">Status</th>
//           <th className="order-table__heading">Type</th>
//           <th className="order-table__heading">Wallet address</th>
//           <th className="order-table__heading">Method</th>
//           <th className="order-table__heading">Hash</th>
//           <th className="order-table__heading">Amount</th>
//           <th className="order-table__heading">Confirm</th>
//         </tr>
//       </thead>
//       <tbody className="order-table__body">
//         {data ? (
//           data.map((e, i) => {
//             const color = e.type === "Withdrawal" ? "red" : "green";
//             if (i === 0) {
//                return(
//                   <tr key={i}></tr>
//                )
//             }else{
//                return (
//                  <tr className="order-table__row" key={i}>
//                    <td className="order-table__item">{e.data}</td>
//                    <td className="order-table__item">{e.status}</td>
//                    <td className="order-table__item" style={{
//                      color: color
//                    }}>{e.type}</td>
//                    <td className="order-table__item">{e.walletAddress ? e.walletAddress : "not specified"}</td>
//                    <td className="order-table__item">{e.method}</td>
//                    <td className="order-table__item">{e.hash}</td>
//                    <td className="order-table__item">{e.amount}</td>
//                    <td className="order-table__item">
//                      <Button
//                        variant="contained"
//                        disabled={e.status === "Pending" || e.status === "Confirm" || declared ? true : false}
//                        onClick={() => {
//                          handleClick({ index: i, key: "status", value: "Pending" });
//                        }}
//                      >
//                        Confirm
//                      </Button>
//                    </td>
//                  </tr>
//                );

//             }
//           })
//         ) : (
//           <></>
//         )}
//       </tbody>
//     </table>
//     </div>
//   );
// }
