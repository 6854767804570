import React from 'react';
import { Box, Typography, Paper } from '@mui/material';
import { UserInformationCard } from './UserInformation.jsx';
import { useOutletContext } from 'react-router-dom';

// import "./documents.scss";

export default function Documents() {
  const { user } = useOutletContext();
  // const { verify } = user;
  const verify = user.verify;
  return (
    <Box sx={styles.page}>
      <Box sx={styles.wrapper}>
        <Box p={5} width='100%' component={Paper}>
          <Typography
            fontWeight={600}
            sx={{
              fontSize: 56,
              '@media (max-width: 720px)': {
                fontSize: 36,
              },
            }}
            color={verify ? 'green' : 'red'}>
            {verify ? 'Verify' : 'You need to confirm the account '}
          </Typography>
        </Box>
        <Box>
          {verify ? (
            <UserInformationCard
              email={user.email}
              firstName={user.firstName}
              lastName={user.lastName}
              number={user.phoneNumber}
              password={user.password}
            />
          ) : (
            <></>
          )}
        </Box>
      </Box>
    </Box>
  );
}


const styles = {
  page: {
    backgroundColor: '#f4f9fd',
    height: '100%',
    width: '100%',
    padding: '20px 40px',
    '@media (max-width: 425px)': {
      padding: '0 15px',
    },
  },
  wrapper: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    justifyContent: 'flex-start',
    rowGap: 5,
  },
  balance: {
    display: 'inline-flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    width: '289px',
    height: '293px',
    backgroundColor: '#fff',
    borderRadius: '50%',
  },
};


