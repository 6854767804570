import React, { useState, useEffect } from "react";
import CoinPage from "./coin/coin.jsx";
import { useOutletContext } from "react-router-dom";
import axios from "../../axios.js";

export default function BitcoinPage(props) {
  const { user, currency, getUserTotalIn } = useOutletContext();

  const pageData = {
    currencyAbbr: "BTC",
    currencyName: "Bitcoin",
    currencyImage: "/assets/icons/bitcoin.svg",
    currencyValue: user.balance.bitcoin,
    toDollar: currency.bitcoin.dollar,
    toEuro: currency.bitcoin.euro,
    toRuble: currency.bitcoin.ruble,
  };
  return (
    <CoinPage
      dataPage={pageData}
      user={user}
      currency={currency}
      getUserTotalIn={getUserTotalIn}
    ></CoinPage>
  );
}
