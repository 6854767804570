import React from 'react';
import { Box } from '@mui/material';
import { useOutletContext } from 'react-router-dom';
import BalanceInfo from '../../balance-info/balance-info.jsx';
import CurrencyData from '../../currency-data/currency-data.jsx';

const Accumulation = () => {
  const { getUserTotalIn, user } = useOutletContext();

  return (
    <Box sx={styles.page}>
      <Box sx={styles.wrapper}>
        <BalanceInfo
          getUserTotalIn={getUserTotalIn}
          bitconValue={user.balance.bitcoin}
        />
        <CurrencyData history={user.history.bitcoin} />
      </Box>
    </Box>
  );
};

const styles = {
  page: {
    backgroundColor: '#f4f9fd',
    height: '100%',
    width: '100%',
    padding: '0 40px',
    '@media (max-width: 425px)': {
      padding: '0 15px',
    },
  },
  wrapper: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    rowGap: 25,
  },
  balance: {
    display: 'inline-flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    width: '289px',
    height: '293px',
    backgroundColor: '#fff',
    borderRadius: '50%',
  },
};

export default Accumulation;
