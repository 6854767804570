// export const baseUrl = (url) => `http://localhost:4000/api/${url}`;
export const baseUrl = (url) => `https://longcrypt.site/api/${url}`;
export function copyObject(object) {
  return JSON.parse(JSON.stringify(object));
}

export const getColorStatus = (value) => {
  if (!value) return '#000';
  let compareValue = value.trim().toLowerCase();
  if (
    compareValue === 'deposit' ||
    compareValue === 'success' ||
    compareValue === 'confirm' ||
    compareValue === 'buy'
  )
    return 'green';
  if (
    compareValue === 'error' ||
    compareValue === 'reject' ||
    compareValue === 'sell' ||
    compareValue === 'need confirm' ||
    compareValue === 'withdrawal'
  )
    return 'red';
  if (compareValue === 'pending') return 'orange';

  return '#000';
};

export function getDate() {
  const date = new Date();

  const monthNames = [
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
    'August',
    'September',
    'October',
    'November',
    'December',
  ];

  const month = monthNames[date.getMonth()];
  const day = date.getDate();
  const hours = date.getHours();
  const minutes = date.getMinutes();
  const seconds = date.getSeconds();

  return `${month} ${day}, ${hours}:${minutes}:${seconds}`;
}
