import React from "react";
import {
  Table,
  TableBody,
  TableHead,
  TableRow,
  TableCell,
  TableContainer,
  Paper,
  Typography,
  Box,
} from "@mui/material";
import bitcoinImage from "../../media/svg/bitcoin.svg";
import litecoinImage from "../../media/svg/litecoin.svg";
import tetherImage from "../../media/svg/tether.svg";
import ethereumImage from "../../media/svg/ethereum.svg";
import axios from "../../axios";
// import "./table.scss";

function createData(name, label, price, balance, value, img) {
  return { name, label, price, balance, value, img };
}

const TableCustom = (props) => {
  const { userBalance, currency } = props;

  const rows = [
    createData(
      "Bitcoin",
      "BTC",
      currency.bitcoin.dollar,
      `${userBalance.bitcoin} BTC`,
      `$ ${(userBalance.bitcoin * currency.bitcoin.dollar).toFixed(2)}`,
      bitcoinImage
    ),
    createData(
      "Litecoin",
      "LTC",
      currency.litecoin.dollar,
      `${userBalance.litecoin} LTC`,
      `$ ${(userBalance.litecoin * currency.litecoin.dollar).toFixed(2)}`,
      litecoinImage
    ),
    createData(
      "Ethereum",
      "ETH",
      currency.ethereum.dollar,
      `${userBalance.ethereum} ETH`,
      `$ ${(userBalance.ethereum * currency.ethereum.dollar).toFixed(2)}`,
      ethereumImage
    ),
    createData(
      "Tether",
      "USDT",
      currency.tether.dollar,
      `${userBalance.tether} USDT`,
      `$ ${(userBalance.tether * currency.tether.dollar).toFixed(2)}`,
      tetherImage
    ),
  ];

  // const data = axios.post()
  return (
    <TableContainer
      sx={{ maxHeight: 600, overflow: "auto" }}
      component={Paper}
    >
      <Table>
        <TableHead>
          <TableRow sx={{ borderBottom: "1px solid #dde9f2" }}>
            {headings.map((heading) => {
              return (
                <TableCell key={heading}>
                  <Typography
                    color='text.secondary'
                    fontWeight={600}
                    fontSize={20}
                  >
                    {heading}
                  </Typography>
                </TableCell>
              );
            })}
          </TableRow>
        </TableHead>
        <TableBody>
          {rows.map((row) => {
            return (
              <TableRow key={row.name}>
                <TableCell>
                  <Box
                    display='flex'
                    columnGap={2}
                  >
                    <img
                      src={row.img}
                      alt='icon'
                    />
                    <Box
                      display='flex'
                      flexDirection='column'
                    >
                      <Typography
                        fontWeight={500}
                        fontSize={18}
                        color='text.primary'
                      >
                        {row.name}
                      </Typography>
                      <Typography
                        fontWeight={300}
                        fontSize={18}
                        color='text.primary'
                      >
                        {row.label}
                      </Typography>
                    </Box>
                  </Box>
                </TableCell>
                <TableCell>
                  <Typography
                    fontWeight={500}
                    fontSize={18}
                    color='text.primary'
                  >
                    {row.price}
                  </Typography>
                </TableCell>
                <TableCell>
                  <Typography
                    fontWeight={500}
                    fontSize={18}
                    color='text.primary'
                  >
                    {row.balance}
                  </Typography>
                </TableCell>
                <TableCell>
                  <Typography
                    fontWeight={500}
                    fontSize={18}
                    color='text.primary'
                  >
                    {row.value}
                  </Typography>
                </TableCell>
              </TableRow>
            );
          })}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

const headings = ["Asset name", "Price", "Balance", "Value"];

export default TableCustom;
