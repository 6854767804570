import React, { useState } from 'react';
import {
  Box,
  Modal,
  TextField,
  Grid,
  Typography,
  Button,
  Stack,
  ButtonGroup,
  Paper,
} from '@mui/material';
import { Euro, AttachMoney, CurrencyRuble } from '@mui/icons-material';
import bitcoinImage from '../../../media/svg/bitcoin.svg';
import { OperationModal } from '../../modal/modal';

export function ActiveBalance(props) {
  const { getUserTotalIn, user, currency } = props;
  const [modalSettings, setModalSettings] = useState({ isOpen: false });
  const balanceInformation = [
    { value: getUserTotalIn('euro'), icon: <Euro color='primary' /> },
    { value: getUserTotalIn('dollar'), icon: <AttachMoney color='primary' /> },
    { value: getUserTotalIn('ruble'), icon: <CurrencyRuble color='primary' /> },
  ];

  function handleCLick(data) {
    setModalSettings(data);
  }
  return (
    <Box minHeight={246} component={Paper} p={3}>
      <Typography fontSize={24} fontWeight={700} mb={5}>
        Bitcoin BTC balance
      </Typography>
      <Stack gap={2} flexDirection='row' mb={5}>
        <Box alignItems='center' display='flex' gap={1}>
          <img src={bitcoinImage} alt='bitoin icon' />
          <Typography fontSize={24}>{user.balance.bitcoin}</Typography>
        </Box>
        <Stack alignItems='center' direction='row' gap={2} flexWrap='wrap'>
          {balanceInformation.map((item, index) => {
            return (
              <Box display='flex' alignItems='center' key={index + item}>
                {item.icon}
                <Typography fontSize={20}>{item.value.toFixed(2)}</Typography>
              </Box>
            );
          })}
        </Stack>
      </Stack>
      <Box
        sx={{ backgroundColor: '#eff3f8' }}
        mb={10}
        p={4}
        ml={-2}
        mr={-2}
        borderRadius={1}>
        <Typography fontSize={24} fontWeight={500} mb={1}>
          Your Bitcoin address
        </Typography>
        <Box p={1.5} borderRadius={1} sx={{ backgroundColor: '#d7e1ed' }}>
          <Typography fontSize={12}>{user.cryptoAddress}</Typography>
        </Box>
      </Box>
      <ButtonGroup fullWidth>
        <Button
          onClick={() =>
            handleCLick({ isOpen: true, heading: 'Bitcoin Buy', type: 'buy' })
          }
          sx={{ color: 'common.white' }}
          color='success'
          variant='contained'>
          Buy crypto
        </Button>
        <Button
          onClick={() =>
            handleCLick({ isOpen: true, heading: 'Bitcoin Sell', type: 'sell' })
          }
          color='error'
          variant='contained'>
          Sell crypto
        </Button>
      </ButtonGroup>
      <OperationModal
        id={user._id}
        currency={currency}
        settings={modalSettings}
        handleCLick={handleCLick}
      />
    </Box>
  );
}
