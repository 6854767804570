import React from 'react';
import CurrencyData from '../../currency-data/currency-data.jsx';
import Calculation from '../../calculation/calculation.jsx';

// import RadioCustom from "../ui/radio/radio.jsx";
import { ActiveBalance } from './activeBalance.jsx';
import { DeclaredBalance } from './declaredBalance.jsx';
import { Box, Grid } from '@mui/material';
import useMediaQuery from '@mui/material/useMediaQuery';

export default function CoinPage(props) {
  const { dataPage, user, currency, getUserTotalIn } = props;
  const matches = useMediaQuery('(max-width:1024px)');

  return (
    <Box sx={styles.page}>
      <Box sx={styles.wrapper}>
        <Grid container spacing={4} direction={matches ? 'column' : 'row'}>
          <Grid item xs={6}>
            {dataPage?.declared ? (
              <DeclaredBalance
                declaredValue={user.balance.declared}
                getUserTotalIn={getUserTotalIn}
              />
            ) : (
              <ActiveBalance
                currency={currency}
                user={user}
                getUserTotalIn={getUserTotalIn}
              />
            )}
          </Grid>
          <Grid item xs={6}>
            <Calculation currency={currency} />
          </Grid>
        </Grid>
        <CurrencyData
          history={user.history.bitcoin}
          declared={dataPage?.declared}
        />
      </Box>
    </Box>
  );
}
const styles = {
  page: {
    backgroundColor: '#f4f9fd',
    height: '100%',
    width: '100%',
    padding: '0 40px',
    '@media (max-width: 425px)': {
      padding: '0 15px',
    },
  },
  wrapper: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    rowGap: 25,
  },
  balance: {
    display: 'inline-flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    width: '289px',
    height: '293px',
    backgroundColor: '#fff',
    borderRadius: '50%',
  },
};


