import React from 'react';
import OrderTable from '../order-table/order-table.jsx';
import { Box, Typography } from '@mui/material';

export default function CurrencyData(props) {
  const { history, declared } = props;

  return (
    <Box width='100%'>
      <Box bgcolor='common.white' p={2}>
        <Typography fontWeight={600} fontSize={22}>
          Order
        </Typography>
      </Box>
      <OrderTable history={history} declared={declared} />
    </Box>
  );
}
