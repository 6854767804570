import React, { useState } from 'react';
import { Box, Typography, Stack, IconButton, Button } from '@mui/material';
import { styled } from '@mui/material/styles';
import {
  Euro,
  AttachMoney,
  CurrencyRuble,
  ArrowDropDown,
} from '@mui/icons-material';

export function Dropdown(props) {
  const { user, currency } = props;
  const [convertValue, setConvertCurrency] = useState('dollar');
  const [open, setOpen] = useState(false);

  const { bitcoin, litecoin, tether, ethereum } = user.balance;
  const totalUserBalance =
    bitcoin * currency.bitcoin[convertValue] +
    litecoin * currency.litecoin[convertValue] +
    tether * currency.tether[convertValue] +
    ethereum * currency.ethereum[convertValue];
  return (
    <Box
      borderRadius={1.5}
      color='common.white'
      position='relative'
      // overflow='auto'
      sx={{ backgroundColor: '#007aff' }}
      p={2}
      zIndex={20}>
      <Stack>
        <Box>
          <Typography mb={0.5}>Total balance</Typography>
          <Typography>ID {user._id.slice(0, 8)}</Typography>
        </Box>
        <Box>
          <Box
            display='flex'
            alignItems='center'
            justifyContent='space-between'
            p={2}
            maxWidth='100%'>
            <Typography sx={{ wordBreak: 'break-all' }} fontSize={30}>
              {totalUserBalance.toFixed(2)}
            </Typography>
            <IconButton
              onClick={() => setOpen(!open)}
              sx={{ color: 'common.white' }}>
              <ArrowDropDown />
            </IconButton>
          </Box>
        </Box>
        <Box
          borderRadius={1.5}
          p={2}
          position='absolute'
          top='90%'
          left={0}
          width='100%'
          sx={{
            backgroundColor: '#007aff',
            transform: `scaleY(${open ? 1 : 0})`,
          }}>
          <Stack>
            <Typography sx={{ opacity: 0.7 }} fontWeight={500} fontSize={14}>
              Default currency
            </Typography>
            <StackButton
              handleClick={() => setConvertCurrency('dollar')}
              icon={<AttachMoney />}
              text='Dollar USA'
            />
            <StackButton
              handleClick={() => setConvertCurrency('euro')}
              icon={<Euro />}
              text='Euro'
            />
            <StackButton
              handleClick={() => setConvertCurrency('ruble')}
              icon={<CurrencyRuble />}
              text='Ruble'
            />
          </Stack>
        </Box>
      </Stack>
    </Box>
  );
}

function StackButton(props) {
  const { icon, text, handleClick } = props;
  return (
    <Box display='flex' alignItems='center' justifyContent='flex-start' gap={2}>
      {icon}
      <Button sx={{ p: 0, justifyContent: 'flex-start' }} onClick={handleClick}>
        <Typography fontSize={12} color='common.white'>
          {text}
        </Typography>
      </Button>
    </Box>
  );
}
