import React from 'react';
// import "./footer.scss";
import { Box, Typography, Container, Stack, Link } from '@mui/material';

const Footer = (props) => {
  return (
    <Box sx={styles.footer} component='footer'>
      <Container>
        <Stack
          display='flex'
          gap={1.5}
          direction='row'
          justifyContent='center'
          alignItems='center'>
          <Link
            sx={{ color: 'grey', opacity: 0.3 }}
            href='./files/privacy-policy.pdf'
            target='_blank'
            color='primary.main'>
            Privacy Policy and Statement
          </Link>
          <Link
            sx={{ color: 'grey', opacity: 0.3 }}
            href='./files/tos.pdf'
            target='_blank'
            color='primary.main'>
            User Agreement
          </Link>
          <Link
            sx={{ color: 'grey', opacity: 0.3 }}
            href='./files/taxes.pdf'
            target='_blank'
            color='primary.main'>
            Customers Tax Responsibilities
          </Link>
        </Stack>
      </Container>
    </Box>
  );
};

export default Footer;

const styles = {
  footer: {
    position: 'fixed',
    bottom: '0',
    left: '0',
    width: '100%',
    minHeight: '45px',
    backgroundColor: '#fff',
    zIndex: '101',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    padding: '10px 0',
    overflow: 'hidden',
  },
};

// const Footer = (props) => {
//   const { isHomePage } = props;
//   return (
//     <footer className="footer">
//       <div className="footer__body">
//         {isHomePage ? (
//           <Typography variant="body1" color="initial">
//             Copyright © 2021 Longcrypt. All rights reserved. Livtarange is part
//             of Bitco Income Property Fund LLC SEC CIK #0001548569
//           </Typography>
//         ) : (
//           <>
//              <Link to={"/"} className="footer__logo">
//                 <img src="/assets/logo_black.png" alt="logo" />
//              </Link>
//             <nav className="footer__nav">
//                 <a href="/assets/files/privacy-policy.pdf" className="footer__link" target={"_blank"}>Privacy Policy and Statement</a>
//                 <a href="/assets/files/taxes.pdf" className="footer__link" target={"_blank"}>User Agreement</a>
//                 <a href="/assets/files/tos.pdf" className="footer__link" target={"_blank"}>Customers Tax Responsibilities</a>
//             </nav>
//           </>
//         )}
//       </div>
//     </footer>
//   );
// };

// export default Footer;
