import React from 'react';
import { Box, Typography, Stack, Paper } from '@mui/material';

import { Euro, AttachMoney, CurrencyRuble } from '@mui/icons-material';

import bitcoinImage from '../../media/svg/bitcoin.svg';

export default function BalanceInfo(props) {
  const { getUserTotalIn, bitconValue } = props;
  const infoValutes = [
    { balance: getUserTotalIn('euro'), icon: <Euro color='primary' /> },
    {
      balance: getUserTotalIn('dollar'),
      icon: <AttachMoney color='primary' />,
    },
    {
      balance: getUserTotalIn('ruble'),
      icon: <CurrencyRuble color='primary' />,
    },
  ];
  return (
    <Box width='100%' p={4} component={Paper}>
      <Typography p={3} textAlign='center' fontWeight={600} fontSize={24}>
        Accumulation balance
      </Typography>
      <Box display='flex' alignItems='center' gap={4}>
        <Box alignItems='center' display='flex' gap={1}>
          <img src={bitcoinImage} alt='bitoin icon' />
          <Typography fontSize={24}>{bitconValue}</Typography>
        </Box>
        <Stack flexWrap='wrap' alignItems='center' direction='row' gap={2}>
          {infoValutes.map((valute, index) => {
            return (
              <Box key={index} display='flex' alignItems='center'>
                {valute.icon}
                <Typography fontSize={20}>
                  {valute.balance.toFixed(2)}
                </Typography>
              </Box>
            );
          })}
        </Stack>
      </Box>
    </Box>
  );
}
