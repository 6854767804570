import React from 'react';
import { Container, Box } from '@mui/material';
import { FormRegistration } from '../../form-registration/form-registration.jsx';

function RegistrationPage() {
  return (
    <Box
      height='100%'
      display='flex'
      justifyContent='center'
      alignItems='center'
      bgcolor='#F2F2F2'
      sx={{
        height: '100%',
        '@media (max-width: 425px)': {
          height: 'auto',
        },
      }}>
      <Container
        sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}>
        <FormRegistration />
      </Container>
    </Box>
  );
}

export default RegistrationPage;
