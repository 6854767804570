import React from 'react';
import {
  TextField,
  FormControlLabel,
  Button,
  Box,
  Checkbox,
  Link,
  Typography,
} from '@mui/material';
import { styled } from '@mui/material/styles';
import { useNavigate } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import { fetchRegister } from '../../redux/slices/auth.js';

const LoginCheckbox = styled(FormControlLabel)({
  fontFamily: 'Gilroy-Light',
});

export const FormRegistration = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const {
    register,
    handleSubmit,
    formState: { isValid },
  } = useForm();

  const onSubmit = async (values) => {
    delete values.check;
    const data = await dispatch(fetchRegister(values));
    if (data.error) {
      alert('Произошла ошибка. Проверьте данные!');
      return;
    }
    navigate('/login');
  };

  return (
    <Box
      onSubmit={handleSubmit(onSubmit)}
      component='form'
      display='flex'
      flexDirection='column'
      rowGap={2}
      maxWidth={600}
      width='100%'
      bgcolor='#fff'
      p={3}
      borderRadius={1}>
      <TextField
        label='User-name'
        variant='outlined'
        fullWidth
        required
        {...register('username', { required: true })}
      />
      <TextField
        {...register('firstName', { required: true })}
        required
        label='First name'
        variant='outlined'
        fullWidth
      />
      <TextField
        {...register('lastName', { required: true })}
        required
        fullWidth
        label='Last name'
        variant='outlined'
      />
      <TextField
        required
        {...register('email', { required: true })}
        fullWidth
        type='email'
        label='E-mail'
        variant='outlined'
      />
      <TextField
        {...register('phoneNumber', { required: true })}
        required
        fullWidth
        type='number'
        label='Phone number'
        variant='outlined'
      />
      <TextField
        required
        {...register('password', { required: true })}
        fullWidth
        type='password'
        label='Password'
        variant='outlined'
      />
      <TextField
        {...register('repeatPassword', { required: true })}
        required
        fullWidth
        type='password'
        label='Repeat password'
        variant='outlined'
      />
      {/* <LoginCheckbox
        control={<Checkbox defaultChecked={false} />}
        label='Send these credentials via email.'
        className='form-registration__checkbox'
      /> */}
      <LoginCheckbox
        {...register('check', { required: true })}
        control={<Checkbox defaultChecked={false} />}
        label='User Agreement.'
      />
      <Typography mt={-2} variant='body1'>
        I read and accept{' '}
        <Link
          href='./files/privacy-policy.pdf'
          target='_blank'
          color='primary.main'>
          User agreement
        </Link>
      </Typography>
      <Button
        fullWidth
        variant='contained'
        type='submit'
        size='large'
        sx={{ p: 2, fontSize: 18 }}
        disabled={!isValid}>
        Register
      </Button>
    </Box>
  );
};
