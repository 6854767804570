import { configureStore } from '@reduxjs/toolkit';
import { userReducer } from './slices/users';
import { authReducer } from './slices/auth';
import { burgerReducer } from './slices/burgerState';

const store = configureStore({
  reducer: { userReducer, auth: authReducer, burgerReducer },
});

export default store;
