import { createSlice, createAsyncThunk} from "@reduxjs/toolkit";


const initialState = {
    user:{
        data: [],
        status: "loading"
    }
}

const userSlice = createSlice({
    name: 'user',
    initialState,
    reducers: {},
})

export const userReducer = userSlice.reducer;