import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
// import "./form-login.scss";

// material
import { Box, TextField, Button, Typography } from '@mui/material';

// custom
import { fetchAuth } from '../../redux/slices/auth';

export const FormLogin = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  function navigateTo(url) {
    navigate(url);
  }
  const {
    register,
    handleSubmit,
    formState: { isValid },
  } = useForm({
    defaultValues: {
      email: '',
      password: '',
    },
    mode: 'onChange',
  });

  const onSubmit = async (values) => {
    let isAuth = false;
    try {
      const data = await dispatch(fetchAuth(values));
      if (!data.payload) {
        return alert('Не удалось авторизоваться!');
      }
      const user_data = JSON.parse(data.payload);
      if (user_data.data.auth) {
        window.localStorage.setItem('id', user_data.data._id);
        isAuth = true;
      }
      if (isAuth) {
        navigate(`/wallet/dashboard`);
      }
    } catch (e) {
      console.error(e);
    }
  };

  return (
    <Box
      display='flex'
      flexDirection='column'
      maxWidth={600}
      width='100%'
      pt={5}
      pb={5}
      pl={3}
      pr={3}
      borderRadius={1}
      bgcolor='#fff'
      rowGap={2}
      component='form'
      onSubmit={handleSubmit(onSubmit)}>
      <TextField
        {...register('email', { required: true })}
        required
        label='Email'
        type='email'
        variant='outlined'
      />
      <TextField
        {...register('password', { required: true })}
        required
        label='Password'
        type='password'
        variant='outlined'
      />
      <Button
        sx={styles.button}
        variant='contained'
        type='submit'
        disabled={!isValid}>
        Log In
      </Button>
      <Box>
        <Button onClick={() => navigateTo('/registration')} type='button'>
          <Typography variant='body1'>Register</Typography>
        </Button>
        <Button onClick={() => navigateTo('/new-password')} type='button'>
          <Typography variant='body1'>Forgot password?</Typography>
        </Button>
      </Box>
    </Box>
  );
};

const styles = {
  button: {
    pt: 2,
    pb: 2,
    ml: 4,
    mr: 4,
    mt: 4,
    '@media (max-width: 425px)': {
      m: 0,
    },
  },
};
