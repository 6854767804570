import React from 'react';
import { Box } from '@mui/material';
import Header from '../../header/header.jsx';
import Footer from '../../footer/footer.jsx';
import SideBar from '../../side-bar/side-bar.jsx';
// import "./personal-area.scss";
import { CircularProgress } from '@mui/material';
import { useFetch } from '../../../hooks.jsx';
import { baseUrl } from '../../../utils.js';
import { Outlet } from 'react-router-dom';
import { redirect } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
const PersonalAreaPage = () => {
  const id = window.localStorage.getItem('id');
  const navigate = useNavigate();
  const {
    response: responseUser,
    error: errorUser,
    isLoading: isLoadingUser,
  } = useFetch(baseUrl(`user/${id}`));
  const {
    response: responseCurrency,
    error: errorCurrency,
    isLoading: isLoadingCurrency,
  } = useFetch(baseUrl('doc/currency'));

  if (isLoadingUser || isLoadingCurrency) return <CircularProgress />;
  if (!isLoadingUser && !isLoadingCurrency && (errorUser || errorCurrency)) {
    return navigate('/');
  }
  const user = responseUser;
  const currency = responseCurrency[0];

  function getUserTotalIn(valute, declared = false) {
    if (declared) {
      return user.balance.declared * currency.bitcoin[valute];
    }
    const userTotalBalance = {
      bitcoin: user.balance.bitcoin * currency.bitcoin[valute],
      litecoin: user.balance.litecoin * currency.litecoin[valute],
      tether: user.balance.tether * currency.tether[valute],
      ethereum: user.balance.ethereum * currency.ethereum[valute],
      getTotal: function () {
        return this.bitcoin + this.litecoin + this.tether + this.ethereum;
      },
    };
    return userTotalBalance.getTotal();
  }

  return (
    <Box
      display='flex'
      flexDirection='column'
      width='100%'
      // height='100%'
    >
      <Header />
      <Box sx={styles.main} component='main'>
        <SideBar user={user} currency={currency} />
        <Box width='100%' pt={2} pb={2}>
          <Outlet context={{ user, currency, getUserTotalIn }} />
        </Box>
      </Box>
      <Footer isHomePage={false} />
    </Box>
  );
};

export default PersonalAreaPage;

const styles = {
  main: {
    marginLeft: '250px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    marginTop: '50px',
    marginBottom: '50px',
    // height: '100%',
    flex: '0 1 calc(100% - 100px)',
    overflow: 'auto',
    '@media (max-width: 1024px)': {
      marginLeft: '0',
      marginRight: '0',
      width: '100%',
    },
  },
};
