import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "../../axios";


export const fetchCurrency = createAsyncThunk("api/getall/currency", async () => {
    const data = await axios.post("/doc/currency/all", null, {
        headers: {
         "Content-Type" : "application/json; charset=utf-8"
        }
    });

    return data;
})
export const fetchAuth = createAsyncThunk("auth/fetchUserData", async (params) => {
  const data = await axios.post("/user/auth", params, {
    headers: {
      "Content-Type" : "application/json; charset=utf-8"
    }
  });
  return JSON.stringify(data);
});

export const fetchAuthMe = createAsyncThunk("auth/fetchAuthMe", async (token) => {
    const {data} = await axios.post("/auth/me", token);
    return data;
})


export const fetchRegister = createAsyncThunk("auth/fetchRegisterUser", async (params) => {
    const data = await axios.post("/user/registration", params, {
        headers: {
            "Content-Type" : "application/json; charset=utf-8"
        }
    })
})
const initialState = {
  data: null,
  status: "loading",
};

const authSlice = createSlice({
  name: "auth",
  initialState,
  extraReducers: {
    [fetchAuth.pending]: (state) => {
      state.status = "loading";
      state.data = null;
    },
    [fetchAuth.fulfilled]: (state, action) => {
      state.status = "loaded";
      state.data = action.payload;
    },
    [fetchAuth.rejected]: (state) => {
      state.status = "error";
      state.data = null;
    },
    [fetchAuthMe.pending]: (state) => {
      state.status = "loading";
      state.data = null;
    },
    [fetchAuthMe.fulfilled]: (state, action) => {
      state.status = "loaded";
      state.data = action.payload;
    },
    [fetchAuthMe.rejected]: (state) => {
      state.status = "error";
      state.data = null;
    },
  },
});

export const selectIsAuth = (state) => Boolean(state.auth.data);
export const authReducer = authSlice.reducer;