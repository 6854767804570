import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Box, Button, TextField, Container } from '@mui/material';
import axios from '../../axios.js';

export default function PageForgetPass() {
  const [password, setPassword] = useState('');
  const [email, setEmail] = useState('');
  const [repeatPassword, setRepeatPassword] = useState('');

  const navigate = useNavigate();

  async function handleSubmit(obj) {
    const { password, repeatPassword, email } = obj;
    if (password !== repeatPassword) {
      alert('Пароли не совпадают');
      setRepeatPassword('');
      setPassword('');
      return;
    }
    if ((password.length || repeatPassword.length) < 5) {
      alert('Пароль слишком короткий! Минимальная длина пароля 6 символов');
      setRepeatPassword('');
      setPassword('');
      return;
    }
    try {
      const response = await axios
        .post('/user/new-password', { email, newPassword: password })
        .then((data) => data)
        .catch((e) => console.error(e));

      if (response.status === 200) {
        alert(response.data.message);
      } else {
        alert('Не удалось обновить пароль');
      }
    } catch (error) {}
    navigate('/login');
  }

  return (
    <Box
      height='100%'
      display='flex'
      justifyContent='center'
      alignItems='center'
      bgcolor='#F2F2F2'>
      <Container
        sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}>
        <Box sx={boxStyles}>
          <TextField
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            label='Email'
            variant='outlined'
            type={'email'}></TextField>
          <TextField
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            label='Password'
            variant='outlined'
            type={'password'}></TextField>
          <TextField
            value={repeatPassword}
            onChange={(e) => setRepeatPassword(e.target.value)}
            label='Repeat password'
            variant='outlined'
            type={'password'}></TextField>
          <Button
            sx={{
              paddingTop: 2,
              paddingBottom: 2,
            }}
            variant='contained'
            color='primary'
            onClick={() => handleSubmit({ password, repeatPassword, email })}>
            Change password
          </Button>
        </Box>
      </Container>
    </Box>
  );
}

const boxStyles = {
  display: 'flex',
  flexDirection: 'column',
  rowGap: 5,
  padding: 4,
  backgroundColor: '#ffffff',
  maxWidth: '520px',
  width: '100%',
  borderRadius: 3,
};
